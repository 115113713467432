.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  color: #ffffff;
  background: #4d4d4d;

  a {
    margin-right: 2rem;
    color: #ffffff !important;
    font-size: 1rem;
  }
}
