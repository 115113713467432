// table
.container-table {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 1.5rem;

  /* width */
  .list_container::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .list_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e3e3e3;
    background-color: #e3e3e3;
    border-radius: 10px;
  }

  /* Handle */
  .list_container::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 10px;
  }

  /* Handle on hover */
  .list_container::-webkit-scrollbar-thumb:hover {
    background: #4e4e4e;
  }
  .arAging-table {
    direction: ltr;
  }
  .list_container {
    direction: rtl;
    overflow: auto;
    height: 450px;
    width: 100%;
    padding-left: 10px;

    table {
      direction: ltr;
      position: relative;
      th {
        font-size: 20px;
        letter-spacing: 1.25px;
        color: #00b6e9;
        position: sticky;
        background-color: white;
        border: none;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      }
      th:first-child {
        color: #2c2c2c;
      }
      td {
        font-size: 18px;
        padding: 2rem 0.5rem;
        border: none;
        border-bottom: 1px solid;
      }
      tr:last-child {
        color: #00b6e9;
      }
      tbody {
        border: none;
      }
    }
  }
}
