.admin-form.view-form {
    input {
        background-color: #fff;
        outline: none;
        border: none;

        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
        }
    }
}