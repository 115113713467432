@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

.tabsUI {
  .contactUs {
    margin-top   : 2em;
    padding      : 4em 2em;
    border-radius: 10px;
    background   : #FFFFFF;
    box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

    .contactUs__header {
      display        : flex;
      flex-direction : column;
      justify-content: center;
      align-items    : center;
      margin         : 1em 0;
      text-align     : center;

      svg {
        width        : 140px;
        margin-bottom: 1.5em;
      }

      span {
        font-size  : 54px;
        font-weight: bold;
        font-family: 'Ubuntu',
          sans-serif;
        letter-spacing: 4px;
      }

      span:nth-of-type(1) {
        color: #0067B3;
      }

      span:nth-of-type(2) {
        color: #00B6E9;
      }
    }

    .contactUs__body {
      display  : flex;
      flex-wrap: wrap;
      width    : 100%;
    }

    .contactUs__info {
      flex           : 1 0 auto;
      display        : flex;
      flex-direction : row;
      justify-content: space-between;
      margin         : 1em;
    }

    .contactUs__wrapper {
      display        : flex;
      justify-content: center;
      align-items    : center;
      width          : 100%;

      svg {
        width       : 42px;
        height      : 42px;
        margin-right: 2em;
        fill        : #0067B3;
      }

      div {
        display        : flex;
        flex-direction : column;
        justify-content: flex-start;
        align-items    : center;

        span {
          color      : #2C2C2C;
          font-size  : 20px;
          font-weight: bold;
        }
      }
    }
  }
}