.paginationUI {
  display        : flex;
  flex-wrap      : wrap;
  flex-direction : row;
  justify-content: space-between;
  align-items    : stretch;
  width          : 100%;
  margin-top     : 1.5em;
  padding        : 1em 0;

  .pagination {
    margin : 0 !important;
    padding: 0 !important;

    .page-item {
      margin: 0.5em 0.5em 0.5em 0;

      &.active .page-link {
        border    : none !important;
        background: #00B6E9 !important;
        cursor    : not-allowed;
      }
    }

    .page-link {
      color        : #FFFFFF !important;
      border       : 1px solid #D4D4D4;
      border-radius: 6px !important;
      background   : #AFAFAF !important;
    }
  }

  .pagination__summary {
    display        : flex;
    justify-content: center;
    align-items    : center;

    span {
      color    : #4D4D4D;
      font-size: 0.98rem;
    }
  }
}