.status-column {
    &.green {
        color: #45C33B;
    }

    &.yellow {
        color: #EAB82B;
    }

    &.red {
        color: #CB1212;
    }
}