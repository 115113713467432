.moveInOutForm {
  .form-select:nth-child(1) {
    max-width     : 240px;
    margin-bottom : 1.5em;
    padding       : 1em;
    color         : #868686;
    font-size     : 20px;
    font-weight   : bold;
    letter-spacing: 2px;
    border        : 1px solid #C7C7C7;
    border-radius : 10px;

    &:focus {
      border-color: #707070;
      outline     : none;
      box-shadow  : none;
    }
  }

  .formUI {
    padding: 2em;
  }
}