.unit-table {
  table {
    width: 100%;

    thead {
      tr {
        th {
          border-right: solid 1px #707070;
          border-bottom: solid 1px #707070;
          padding: 1em 1em 1em 1em;

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border: none;
          border-right: solid 1px #707070;
          border-bottom: solid 1px #707070;

          input {
            width: 100%;
            padding: 1em 1em 1em 1em;
            border: none;
          }

          &:last-child {
            border-right: none;
          }

          select#classifications {
            cursor: pointer;
            width: 100%;
            padding: 1em 3em 1em 1em;
            border: none;

            &.editable-cell {
              padding: 1em 2.5em 1em 1em;
            }
          }
        }
      }
    }
  }
}
