.dashboard_card {
    position       : relative;
    display        : flex;
    flex-wrap      : nowrap;
    flex-direction : column;
    justify-content: space-between;
    width          : 100%;
    min-height     : 200px;
    padding        : 2rem;
    border-radius  : 14px;
    transition     : all 0.4s ease-out;

    .card__link {
        position: absolute;
        top     : 0;
        left    : 0;
        z-index : 2;
        width   : 100%;
        height  : 100%;
        cursor  : pointer;
    }

    .card__header {
        position      : relative;
        display       : flex;
        flex-wrap     : nowrap;
        flex-direction: row;
        align-items   : flex-start;

        .wrapper {
            position     : absolute;
            z-index      : 3;
            padding-right: 1rem;
            background   : #EAB72B;

            select {
                width: initial;
            }
        }

        div:last-child {
            display    : flex;
            align-items: center;
            width      : 100%;
            height     : 38px;

            div {
                width     : 100%;
                height    : 3px;
                background: #FFFFFF;
            }
        }
    }

    .card__body {
        position       : relative;
        display        : flex;
        justify-content: space-between;
        align-items    : flex-end;
        height         : 100%;
    }

    .card__title {
        color    : #FFFFFF;
        font-size: 1.5rem;
    }

    svg {
        width : 3rem;
        height: 3rem;
        fill  : #FFFFFF;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transform : scale(1.05);
    }
}