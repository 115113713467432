.occupancyReports {
  border-radius: 10px;
  background   : #FFFFFF;
  box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  ::-webkit-scrollbar {
    width : 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background   : #E3E3E3;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background   : #4E4E4E;
  }
}