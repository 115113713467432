.table-container {
    width: 100%;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        height: 15px;
        position: relative;
        z-index: 9999;
    }
      

    &::-webkit-scrollbar-track {
        background-color: #AFAFAF;
        border-radius: 10px;
      }
       
    &::-webkit-scrollbar-thumb {
        background: #4E4E4E; 
        border-radius: 10px;
    }
    
        &::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
        background: #383838; 
    }

    .admin-table.occupy-table {
        thead {
            tr {
                height: 50px;

                th {
                    border-right: 1px solid #AFAFAF;
                    text-align: center;
                    padding: 0;
                }

                th.sub-header {
                    padding: 0 1em;

                    &:nth-child(odd) {
                        border-right: none;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    border-bottom: none;
                    border-right: 1px solid #AFAFAF;
                    text-align: center;

                    &:nth-child(odd) {
                        border-right: none;
                    }
                }
            }
        }
    }
}