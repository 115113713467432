.workPermit_viewRequest {
  padding      : 3em;
  border-radius: 10px;
  background   : #FFFFFF;
  box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  &>.row>.col>div,
  &>.row>.col-lg-4>div {
    display       : flex;
    flex-direction: column;
    margin        : 0 1em 2em 0;

    h6 {
      color      : #4E4E4E;
      font-size  : 18px;
      font-weight: bold;
    }

    span {
      color: #4E4E4E;
    }

    .form-label {
      display    : flex;
      align-items: center;
      margin     : 0;
      height     : 58px;
      color      : #4E4E4E;
      font-size  : 18px;
      font-weight: bold;
    }

    .form-control {
      padding: 1em;
      border : 1px solid #707070;

      &:focus {
        outline   : none;
        box-shadow: none;
      }
    }

    button {
      display    : flex;
      align-items: center;
      width      : fit-content;
      margin     : 2em 0;

      svg {
        margin-right: 1em;
        width       : 20px;
        font-size   : 18px;
        fill        : #FFFFFF;
      }
    }
  }

  .personnelList {
    .personnelList__header {
      display      : flex;
      margin-bottom: 1em;

      h6 {
        width       : 100%;
        max-width   : 420px;
        margin-right: 2em;
        color       : #4E4E4E;
        font-size   : 18px;
        font-weight : bold;
      }
    }

    .personnelList__body {
      &>div {
        display    : flex;
        align-items: baseline;

        &>div:nth-of-type(1) {
          width    : 100%;
          max-width: 420px;
          margin   : 0 2em 2em 0;
        }

        &>div:nth-of-type(2) {
          display       : flex;
          flex-direction: row;
          align-items   : center;
          width         : 100%;

          .form-label {
            span {
              margin-right : 1em;
              padding      : 1em 1.75em;
              color        : #FFFFFF;
              font-weight  : normal;
              border-radius: 10px;
              background   : #00B6E9;
              cursor       : pointer;
            }
          }

          .form-control {
            display: none;
          }

          &>span {
            color: #AFAFAF;
          }
        }
      }
    }
  }

  &>h6 {
    margin     : 2em 0;
    color      : #00B6E9;
    font-weight: bold;
  }

  &>div:last-child {
    display        : flex;
    justify-content: flex-end;
    margin-top     : 4em;

    div {
      display       : flex;
      flex-direction: column;

      &>h6 {
        color      : #4E4E4E;
        font-weight: bold;
      }

      &>div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 1em 0;
        width: 100%;

        .btn-approve {
          margin: 0 1em 1em 0;
          color: #ffffff;
          background: #00b6e9;
        }

        .btn-deny {
          margin: 0 1em 1em 0;
          color: #ffffff;
          background: #cb1212;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .btn-light {
          margin: 0 0 1em 0;
        }
      }
    }
  }
}