.formUI {
  border-radius: 10px;
  background   : #FFFFFF;
  box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .form-label {
    color      : #4E4E4E;
    font-size  : 18px;
    font-weight: bold;
  }

  .form-control,
  .form-select {
    padding: 1em;
    border : 1px solid #707070;

    &::placeholder {
      color: #AFAFAF;
    }

    &:focus {
      border-color: #707070;
      outline     : none;
      box-shadow  : none;
    }
  }

  h5 {
    margin     : 2em 0 1em;
    color      : #4E4E4E;
    font-size  : 20px;
    font-weight: bold;
  }

  .input_file {
    .form-label {
      cursor: pointer;

      .input_file__body {
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
      }

      .svg__wrapper {
        width       : 100px;
        height      : 100px;
        margin-right: 2em;
        background  : #F7F7F7;
      }

      span {
        color      : #AFAFAF;
        font-size  : 16px;
        font-weight: normal;
      }
    }

    .form-control[type='file'] {
      display: none;
    }
  }


  .formUI__footer {
    display        : flex;
    justify-content: flex-end;
    align-items    : center;
    margin-top     : 3em;
    padding        : 1em 0;

    .btn-submit {
      margin    : 0 1em 1em 0;
      color     : #FFFFFF;
      background: #00B6E9;
    }

    .btn-cancel {
      margin    : 0 0 1em 0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }

}