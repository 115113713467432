.expendituresFilter {
  display        : flex;
  flex-wrap      : nowrap;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : stretch;

  .button--filter {
    display    : flex;
    align-items: center;
    height     : 66px;
    margin     : 0 1em 1em 0;

    svg {
      width       : 20px;
      height      : 20px;
      margin-right: 0.5em;
    }

    span {
      font-size: 20px;
    }
  }

  .filterUI {
    .form-group {
      display       : flex;
      flex-direction: row;
      flex          : wrap;
      align-items   : center;
      margin        : 0 1em 1em 0;
      padding       : 0;
      height        : 66px;


      &:first-child {
        margin-right: 3em;
      }
    }
  }

  .buttonWrapper {
    display    : flex;
    flex       : wrap;
    align-items: stretch;
    flex       : 1;
    width      : 100%;
    margin     : 1em 0;

    &>button {
      display      : flex;
      flex         : nowrap;
      align-items  : center;
      height       : 66px;
      margin-bottom: 1em;
      font-size    : 20px;

      svg {
        width       : 20px;
        height      : 20px;
        margin-right: 0.5em;
      }
    }

    &>button:first-child {
      margin: 0 1em 1em 0;
    }
  }
}

.filterModal {
  display        : flex;
  flex           : wrap;
  flex-direction : row;
  justify-content: center;

  .col {
    margin: 0 1.5em;
  }

  h6 {
    margin-bottom: 1.25em;
    color        : #00B6E9;
    font-weight  : bold;
    white-space  : nowrap;
  }

  .filterModal__column {
    display       : flex;
    flex          : wrap;
    flex-direction: column;
    height        : 100%;
    margin        : 0;
    padding       : 0;

    button {
      margin    : 0.5em 0;
      padding   : 0;
      color     : #C0C0C0;
      font-size : 14px;
      text-align: left;
      background: none !important;
    }

    button:hover {
      color: #C0C0C0;
    }

    button.active {
      color      : #00B6E9;
      font-weight: bold;
    }

    .checkbox__container {
      display      : block;
      position     : relative;
      padding-left : 35px;
      margin-bottom: 12px;
      cursor       : pointer;
      font-size    : 14px;

      &:hover input~.checkmark {
        background-color: #ccc;
      }

      input:checked~.checkmark {
        background-color: #00B6E9;
      }

      input {
        position: absolute;
        opacity : 0;
        cursor  : pointer;
        height  : 0;
        width   : 0;

        &:checked~.checkmark:after {
          display: block;
        }
      }

      span:nth-of-type(1) {
        white-space: nowrap;
      }

      .checkmark {
        position     : absolute;
        top          : 0;
        left         : 0;
        height       : 20px;
        width        : 20px;
        background   : #eee;
        border-radius: 5px;

        &::after {
          content : "";
          position: absolute;
          display : none;
        }
      }
    }
  }
}

.button--ok {
  background: #00B6E9 !important;
}

.button--cancel {
  color     : #00B6E9 !important;
  background: #FFFFFF !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}