.moveInOutTable {
  .dropdown-item {
    padding: 1rem !important;
  }
  .dropdown-item:first-child {
    border-bottom: 1px solid #a59d9d;
    text-align: center;
  }
  .dropdown-menu.show {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(-100px, 42px) !important;
  }
  .tableUI {
    table {
      .show > .btn-primary.dropdown-toggle {
        color: black;
        background-color: white;
        border-color: white;
        box-shadow: none;
      }
      .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: none !important;
      }
      .container-table button {
        padding: 0;
      }
      .dropdown-toggle::after {
        content: none;
      }
      button.btn.btn-primary {
        background-color: white;
        color: black;
        padding: 0;
      }
      thead {
        tr th {
          text-align: left;
        }
      }

      tbody {
        tr td {
          text-align: left;
          border-color: #d4d4d4 !important;
        }

        tr td:first-child {
          a {
            color: #2c2c2c;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
