.tabsUI {
  &>.form-select {
    width        : 100%;
    max-width    : 240px;
    margin-bottom: 1em;
    padding      : 1em;
    color        : #868686;
    font-size    : 18px;
    border       : 1px solid #C7C7C7;
    border-radius: 10px;
  }
}