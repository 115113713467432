.applicationForm.car-sticker {
  padding: 0;

  & > .formUI {
    padding: 2em;

    h3 {
      color: #00b6e9;
      font-weight: bold;
      font-family: Helvetica;
      font-size: 20px;
    }
  }
}
