.registrationList{
    
  .upload-btn {
    background: #00b6e9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border-radius: 10px;
    color: white;
    &:hover {
      color: white;
    }
  }

  .form-select {
    height: 3rem;
    color: #868686;
  }
  #formSearch {
    height: 3rem;
    color: #868686;
  }
  .container-table {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 1.5rem;
  }
  .dropdown-item {
    padding: 1rem !important;
  }
  .dropdown-item:first-child{
    border-bottom: 1px solid #a59d9d;
  }
  .dropdown-menu.show {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(-100px, 42px) !important;
  }
  .table > :not(:first-child) {
    border: none !important;
  }
  .table > :not(caption) > * > * {
    border: none !important;
  }

  .filter {
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #00b6e9;

      border-color: none;
    }
    .dropdown-menu.show {
      position: absolute;
      transform: translate(149px, -14px) !important;
      text-align: center;
    }
    .dropdown-item {
      padding: 0.7rem !important;
    }
    .dropdown-item {
      border-bottom: 1px solid #a59d9d;
    }
    .dropdown-item:last-child {
        border-bottom: none;
      }
  }
}