.review {
  background-color: white;
  padding: 2rem;
  .headerInformation {
    letter-spacing: 0px;
    color: #00b6e9;
    font-size: 22px;
    font-weight: 700;
  }
  .upload-btn {
    background: #00b6e9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border-radius: 10px;
    color: white;
    &:hover {
      color: white;
    }
  }

  .form-select {
    height: 3rem;
    color: #868686;
  }
  #formSearch {
    height: 3rem;
    color: #868686;
  }
  .container-table {
    background: #f3ecec 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 1.5rem;
  }
  .dropdown-item {
    padding: 1rem !important;
  }
  .dropdown-item:first-child {
    border-bottom: 1px solid #a59d9d;
  }
  .dropdown-menu.show {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(-100px, 42px) !important;
  }
  .table > :not(:first-child) {
    border: none !important;
  }
  .table > :not(caption) > * > * {
    border: none !important;
  }
  .table {
    direction: ltr;
    position: relative;
    border:none th {
      font-size: 20px;
      letter-spacing: 1.25px;
      color: #2c2c2c;
      position: sticky;
      background-color: white;
      border: none;
      top: 0; /* Don't forget this, required for the stickiness */
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }
    td {
      font-size: 18px;
      padding: 2rem 0.5rem;
    }
    thead tr {
      border: none;
    }
    tr {
      border-top: 1px solid #edd6d6;
    }
    tbody {
      border: none;
    }
    .account-type,
    .unit,
    .total {
      color: #00b6e9;
    }

    .show > .btn-primary.dropdown-toggle {
      color: black;
      background-color: white;
      border-color: white;
      box-shadow: none;
    }
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: none !important;
    }
    .container-table button {
      padding: 0;
    }
    .dropdown-toggle::after {
      content: none;
    }
    button.btn.btn-primary {
      background-color: white;
      color: black;
      padding: 0;
    }
  }
  .filter {
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #00b6e9;

      border-color: none;
    }
    .dropdown-menu.show {
      position: absolute;
      transform: translate(149px, -14px) !important;
      text-align: center;
    }
    .dropdown-item {
      padding: 0.7rem !important;
    }
    .dropdown-item {
      border-bottom: 1px solid #a59d9d;
    }
    .dropdown-item:last-child {
      border-bottom: none;
    }
  }
}

.pagination-review p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}
