.amenitiesForm {
  .formUI {
    padding: 1em 2em;

    &>h6 {
      margin     : 2em 0;
      color      : #00B6E9;
      font-weight: bold;
    }

    &>.row>.col>div,
    &>.row>.col-lg-4>div {
      display       : flex;
      flex-direction: column;
      margin        : 0 1em 2em 0;

      h6 {
        color      : #4E4E4E;
        font-size  : 18px;
        font-weight: bold;
      }

      span {
        color: #4E4E4E;
      }

      .form-label {
        display    : flex;
        align-items: center;
        margin     : 0;
        height     : 58px;
        color      : #4E4E4E;
        font-size  : 18px;
        font-weight: bold;
      }

      .form-control {
        padding: 1em;
        border : 1px solid #707070;

        &:focus {
          outline   : none;
          box-shadow: none;
        }
      }

      button {
        display    : flex;
        align-items: center;
        width      : fit-content;
        margin     : 2em 0;

        svg {
          margin-right: 1em;
          width       : 20px;
          font-size   : 18px;
          fill        : #FFFFFF;
        }
      }
    }
  }
}