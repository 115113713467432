@import '../GovernmentRequirementsPage.scss';

.calendar {
    h2 {
        color: $primaryColor;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 1em;
    }

    .calendar-container {
        background-color: #F9F9F9;
        padding: 2em 1em;
        box-shadow: 0px 3px 6px #00000029;

        .rbc-calendar {
            .rbc-toolbar {
                position: relative;
                margin: 0 0 3em;

                .rbc-btn-group {
                    position: absolute;
                    left: 0;
                    display: flex;
                    align-items: center;

                    button {
                        background-color: #00B6E9;
                        border: none;
                        padding: 6px 20px;
                        line-height: 20px;

                        svg {
                            height: 100%;
                            width: auto;
                        }
                    }

                    .btn-back {
                        border-radius: 7px 0 0 7px;
                    }

                    .btn-next {
                        border-radius: 0 7px 7px 0;
                    }

                    .select-month {
                        display: inline-block;
                        margin-left: 2em;
                        width: 100%;
                        background-color: $primaryColor;
                        color: #fff;
                        cursor: pointer;
                        padding: 3px 13px;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                        border: none;
                        border-radius: 7px;

                        &:focus,
                        &:active,
                        &:hover {
                            outline: 0;
                            border: 0;
                        }
                    }
                }

                .rbc-toolbar-label {
                    color: #0067B3;
                    font-weight: bold;
                    font-size: 20px;
                }
            }

            .rbc-month-row {
                .rbc-row-bg {
                    .rbc-day-bg {
                        flex: 1 0;
                        border-bottom: 1px solid #2C2C2C;
                        border-right: 1px solid #2C2C2C;
                    }
                }

                .rbc-row-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    padding-bottom: 5px;
                }
            }

            .rbc-month-view {
                border-top: 1px solid #2C2C2C;
                border-left: 1px solid #2C2C2C;

                .rbc-row {
                    margin-bottom: 0;
                    
                    .rbc-date-cell {
                        padding: 5px 5px 0 0;
                    }

                    .rbc-row-segment {
                        margin-right: 5px;

                        .rbc-event {
                            width: 1em;
                            height: 1em;
                            border-radius: 50%;
                            background-color: #48d96f;
                            margin-left: auto;

                            .rbc-event-content {
                                display: none;
                            }
                        }
                    }
                }
            }

            .rbc-month-header {
                .rbc-header {
                    border-bottom: 1px solid #2C2C2C;
                    border-right: 1px solid #2C2C2C;
                    padding: 1em;

                    span {
                        font-size: 20px;
                        color: #575757;
                    }
                }
            }
        }
    }

    .calendar-container-list {
        .calendar-list {
            overflow-y: scroll;
            height: 28em;

            .list {
                background-color: #F9F9F9;
                box-shadow: 0px 3px 6px #00000029;
                padding: 2em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #2C2C2C;

                .text {
                    h4 {
                        color: #0067B3;
                        font-weight: bold;
                        font-size: 20px;
                        margin-bottom: 1em;
                    }

                    p {
                        color: #2C2C2C;
                        font-size: 19px;
                        margin-bottom: 0.5em;
                    }
                }
            }
        }
    }
}