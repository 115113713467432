.admin-form.edit-form {
    h3 {
        text-align: left;
        font: normal normal bold 18px/18px Helvetica;
        letter-spacing: 0px;
        color: #00B6E9;
        opacity: 1;
    }

    .message-label {
        .success {
            color: rgb(0, 207, 17);
            font-size: 14px;
        }

        .error {
            color: rgb(202, 11, 11);
            font-size: 14px;
        }
    }
}