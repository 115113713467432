.viewRequest {
    background: white;
    padding: 3rem 3rem;
    border-radius: 1rem;
  
    .headerInformation {
      letter-spacing: 0px;
      color: #00b6e9;
      font-size: 22px;
      font-weight: 700;
    }
    .approval{
      font-size: 22px;
      font-weight: 700;
    }
    .remarksLabel{
        font-size: 22px;
        font-weight: 700;
    }
  }
  