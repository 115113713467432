.dashboardConcernsTable {
  .tableUI {
    table {
      thead {
        tr th {
          text-align: left;
        }
      }

      tbody {
        tr td {
          text-align: left;
          border-color: #d4d4d4 !important;

          .action-button {
            background-color: transparent !important;
            width: 50px;
            padding: 0;
            height: 100%;
            border: none;
            outline: none;

            svg {
              width: 36px;
              height: min-content;
            }
          }
        }

        tr td:first-child {
          a {
            color: #2c2c2c;
          }
        }
      }
    }
  }
}
