.workPermitForm {
  & > .formUI {
    padding: 2em;

    & > form > .row > .col-lg-4 > {
      div {
        h6 {
          font-weight: bold;
        }
      }
    }

    .personnelList {
      .personnelList__header {
        display: flex;
        margin-bottom: 1em;

        h6 {
          width: 100%;
          max-width: 420px;
          margin-right: 2em;
          color: #4e4e4e;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .personnelList__body {
        & > div {
          display: flex;
          align-items: baseline;

          & > div:nth-of-type(1) {
            width: 100%;
            max-width: 420px;
            margin: 0 2em 2em 0;
          }

          & > div:nth-of-type(2) {
            .form-label {
              span {
                margin-right: 1em;
                padding: 1em 1.75em;
                color: #ffffff;
                font-weight: normal;
                border-radius: 10px;
                background: #00b6e9;
                cursor: pointer;
              }
            }

            .form-control {
              display: none;
            }

            & > span {
              color: #afafaf;
            }
          }
        }
      }
    }

    .react-datetimerange-picker {
      width: 100%;
      height: 58px;

      .react-datetimerange-picker__wrapper {
        border-radius: 4px;
        padding: 0 1em;
      }
    }
  }
}

.modal-renovation {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .modal-cont {
          flex-direction: column;
          gap: 2em;
          padding: 2em;
          text-align: center;

          img {
            width: 150px;
            margin: 0 auto;
          }

          h2 {
            color: #00B6E9;
            font-size: 20px;
            font-family: 'Helvetica', sans-serif;
            font-weight: 600;
          }

          p {
            color: #2C2C2C;
            font-size: 18px;
            font-family: 'Helvetica', sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }
}
