.dashboardBudgetTable {
  h5 {
    margin-bottom: 1em;
    color        : #00B6E9;
    font-weight  : bold;
  }

  .dashboardBudgetTable__content {
    display      : flex;
    height       : 720px;
    border-radius: 10px;
    background   : #FFFFFF;
    box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow     : scroll;
    direction    : rtl;

    &>.col:nth-child(1) {
      padding: 0;

      &>div {
        direction: ltr;

        &>table {

          thead {
            tr th {
              text-align: left;
            }
          }

          tbody {
            tr td {
              text-align: left;
            }
          }
        }
      }
    }

    &>.col:nth-of-type(2) {
      padding: 0;

      &>div {
        border-right: 1px solid #707070;

        &>table {
          thead {
            tr th {
              text-align: left;
            }
          }

          tbody {
            tr td {
              text-align: left;
            }

            tr:last-child td:nth-child(3) {
              color      : #00B6E9;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .tableUI {
    border-radius: 10px;
    background   : none;
    box-shadow   : none !important;
    direction    : ltr;

    table {
      margin         : 0;
      margin-bottom  : 3em;
      border         : none !important;
      border-collapse: separate;
      border-spacing : 0;
      direction      : ltr;

      thead {
        position  : sticky;
        top       : 0;
        background: #FFFFFF;
        z-index   : 99;

        tr th {
          padding    : 2em;
          color      : #00B6E9;
          white-space: nowrap;
        }
      }

      tbody {
        tr td {
          padding    : 1.25em 2em;
          color      : #4E4E4E;
          font-size  : 16px;
          white-space: nowrap;
          border     : none !important;
        }


        tr:last-child td {
          position  : sticky;
          bottom    : 0;
          padding   : 2em;
          border-top: 2px dashed #D4D4D4 !important;
          background: #FFFFFF;
        }
      }
    }
  }
}



::-webkit-scrollbar {
  width : 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background   : #E3E3E3;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background   : #4E4E4E;
}