.topbar {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2.75rem;

  .svg__wrapper {
    margin-right: 0 !important;
    cursor: pointer;
  }

  .topbar__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .topbar__header span {
    color: #00b6e9;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .topbar__header Button {
    margin-right: 1rem;
    padding: 0.5rem !important;
  }

  .topbar__header Button svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
  }

  .topbar__account {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #00b6e9;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 120%;

    .topbar__user {
      display: flex;
      flex-direction: column;
      margin-right: 3rem;

      .top-bar-unit {
        .css-1s2u09g-control {
          border: none;
          padding: 0;
          min-height: min-content;

          .css-319lph-ValueContainer {
            padding: 0;

            .css-qc6sy-singleValue {
              color: #00b6e9;
            }
          }

          .css-1hb7zxy-IndicatorsContainer {
            .css-1okebmr-indicatorSeparator {
                display: none;
            }

            .css-tlfecz-indicatorContainer {
                padding: 0 8px 0 2px;

                &:hover {
                    padding: 0 8px 0 2px;
                }
            }
          }
        }

        .css-1pahdxg-control {
          padding: 0;

          .css-319lph-ValueContainer {
            padding: 0;

            .css-qc6sy-singleValue {
              color: #00b6e9;
            }

            .css-6j8wv5-Input {
              margin: 0;
            }
          }

          .css-1hb7zxy-IndicatorsContainer {
            .css-1okebmr-indicatorSeparator {
                display: none;
            }

            .css-tlfecz-indicatorContainer,
            .css-1gtu0rj-indicatorContainer {
                padding: 0 8px 0 2px;

                &:hover {
                    padding: 0 8px 0 2px;
                }
            }
          }

          &:hover {
            border: none !important;
            box-shadow: none !important;
            min-height: min-content;

            .css-319lph-ValueContainer {
              padding: 2px 0;

              .css-qc6sy-singleValue {
                color: #00b6e9;
              }

              .css-6j8wv5-Input {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .topbar__account button {
    margin-right: 1rem;
    padding: 0;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
  }

  .topbar__account button svg {
    width: 32px;
    height: 32px;
    fill: #00b6e9;
    margin: 0;
  }

  .topbar__account .svg__wrapper svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
  }
}

.popover {
  width: 180px;
  border: none !important;
}

.popover .popover-header {
  color: #ffffff;
  text-align: center;
  background: #00b6e9;
}

.popover .popover-body {
  padding: 1rem 2rem;
  text-align: center;
}

.popover .popover-body a {
  color: #4e4e4e;
  text-decoration: none;
}
