.dashboardOccupancyReports {
  .tableUI {
    padding   : 2em;
    background: none;
    box-shadow: none !important;

    table {
      margin       : 0;
      margin-bottom: 3em;
      border       : none !important;

      thead {
        tr th {
          padding    : 1.25em 2em;
          color      : #00B6E9;
          white-space: nowrap;
        }

        tr:first-child th {
          height     : 50px;
          padding    : 0;
          font-weight: bold;
        }
      }

      tbody {
        tr td {
          padding    : 1.25em 2em;
          color      : #4E4E4E;
          font-size  : 16px;
          white-space: nowrap;
          border     : none !important;
        }
      }
    }
  }

  &.detailsTable {
    position: relative;
    top     : 0;
    left    : 0;
    z-index : 3;

    thead {
      tr th {
        text-align: left;
        background: #FFFFFF;
      }
    }

    tbody {
      tr td {
        text-align: left;
        background: #FFFFFF;
      }
    }
  }

  &.reportsTable {
    thead {
      tr th {
        text-align: center;

        &.divider {
          border-right: 1px solid #868686 !important;
        }

        &.divider:last-child {
          border: none !important;
        }
      }
    }

    tbody {
      tr td {
        font-weight: 300;
        text-align : center;

        &:nth-child(2n):not(:last-child) {
          border-right: 1px solid #868686 !important;
        }
      }
    }
  }
}