.expendituresGraph {
  padding      : 1em 0;
  border-radius: 10px;
  background   : #FFFFFF;
  box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  .expendituresGraph__header {
    border-bottom: 1px solid #D4D4D4;
  }

  .expendituresGraph__row {
    display       : flex;
    flex-direction: row;
    flex-wrap     : nowrap;
    width         : 100%;
    padding       : 0 1em;

    &>div {
      max-width: 280px;
      flex     : 1 0 auto;
      padding  : 2em;

      h6 {
        color      : #00B6E9;
        font-weight: bold;
      }

      p {
        color: #00B6E9;
      }
    }
  }
}