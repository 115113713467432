.modalUI {
  position  : fixed;
  top       : 0;
  left      : 0;
  z-index   : 99999;
  width     : 100%;
  height    : 100vh;
  background: rgba(0, 0, 0, 0.5);

  .modal-dialog {
    top: 200px;
  }

  .modal-content {
    border       : none;
    border-radius: 0;
  }

  .modal-header {
    margin-bottom: 2em;
    padding      : 1em;
    border       : none;
  }

  .modal-body {
    margin : 1em 0;
    padding: 1em;
  }

  .modal-footer {
    margin-top: 2em;
    padding   : 1em;
    border    : none;
  }
}