.billing {
  .dropdown-toggle::after {
    content: none;
  }

  .search {
    padding: 0.8rem;
  }

  .filter {
    .show>.btn-primary.dropdown-toggle {
      color           : #fff;
      background-color: #00b6e9;

      border-color: none;
    }

    .dropdown-menu.show {
      position  : absolute;
      transform : translate(124px, -14px) !important;
      text-align: center;
    }

    .dropdown-item {
      padding: 0.7rem !important;
    }

    .dropdown-item:first-child {
      border-bottom: 1px solid #a59d9d;
    }
  }



  .pagination {
    margin-top     : 2rem;
    justify-content: center;
    align-items    : center;
    display        : flex;

    p {
      margin: 0 2rem 0 0;
    }

    .page-link {
      background   : #00b6e9 0% 0% no-repeat padding-box;
      border-radius: 10px;
      color        : white;
    }
  }

}