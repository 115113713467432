.calendar-cont {
    text-align: right;

    .calender-btn {
        padding-left: 3em;
        position    : relative;

        &::before {
            content        : "";
            width          : 15px;
            height         : 15px;
            position       : absolute;
            background     : url('../../../assets/svg/calendar.svg');
            background-size: contain;
            left           : 20px;
            top            : 0;
            bottom         : 0;
            margin         : auto;
        }
    }
}