.paymnentHistory-container-table {
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 10px;
  box-shadow: 0 3px 6px #00000029;
  padding: 1.5rem;
  table > :not(:first-child) {
    border: none !important;
  }
  table > :not(caption) > * > * {
    border: none !important;
  }
  table {
    direction: ltr;
    position: relative;
    border:none th {
      font-size: 20px;
      letter-spacing: 1.25px;
      color: #2c2c2c;
      position: sticky;
      background-color: white;
      border: none;
      top: 0; /* Don't forget this, required for the stickiness */
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }
    .account-type,
    .unit,
    .total {
      color: #00b6e9;
    }

    tbody {
      border: none;
      td {
        font-size: 18px;
        padding: 2rem 0.5rem;
        border-bottom: 1px solid #868686 !important;
        .dropdown-item {
          padding: 0.7rem !important;
        }
        .dropdown-item {
          border-bottom: 1px solid #a59d9d;
        }
        .dropdown-item:last-child {
          border-bottom: none;
        }
        .dropdown-menu.show {
          transform: translate(-80%, 42px) !important;
          text-align: center;
        }
      }
      .show > .btn-primary.dropdown-toggle {
        color: black;
        background-color: white;
        border-color: white;
        box-shadow: none;
      }
      .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: none !important;
      }
      .container-table button {
        padding: 0;
      }
      .dropdown-toggle::after {
        content: none;
      }
      button.btn.btn-primary {
        background-color: white;
        color: black;
        padding: 0;
      }
    }
  }
}
.pagination {
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  p {
    margin: 0 2rem 0 0;
  }
  .page-link {
    background: #00b6e9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: white;
  }
}
