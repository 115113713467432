.card.admin-form.edit-unit {
  form {
    .formParkingSlots,
    .formOwner,
    .formTenant {
      & > * {
        height: 100% !important;
      }

      .css-319lph-ValueContainer {
        padding: 0.5rem 2.25rem 0.5rem 0.75rem;
      }
    }

    .readonly-cell {
      background-color: #fff;
      border: none;

      &:focus {
        box-shadow: unset;
      }
    }
  }
}
