.concerns_addRemarks {
  padding: 3em;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  & > .row > .col > div {
    margin: 0 1em 2em 0;

    h6 {
      color: #4e4e4e;
      font-size: 18px;
      font-weight: bold;
    }

    span {
      color: #4e4e4e;
    }

    .photo {
      position: relative;
      width: 100%;
      max-width: 470px;
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      cursor: pointer;
      margin: 0 0 1em;

      div:nth-of-type(1) {
        position: absolute;
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: transparent;

        span {
          margin-top: 1em;
          color: #ffffff;
          font-size: 18px;
        }

        svg {
          width: 48px;
        }
      }

      div:nth-of-type(2) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  & > .row > .col-lg-12 > div {
    max-width: 720px;
    margin: 0 1em 2em 0;

    h6 {
      color: #4e4e4e;
      font-size: 18px;
      font-weight: bold;
    }

    span {
      color: #4e4e4e;
    }
  }

  & > div {
    margin-top: 2em;

    h6 {
      color: #00b6e9;
      font-weight: bold;
    }
  }

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 4em;

    div {
      display: flex;
      flex-direction: column;

      & > h6 {
        color: #4e4e4e;
        font-weight: bold;
      }

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 1em 0;

        .btn-approve {
          margin: 0 1em 1em 0;
          color: #ffffff;
          background: #00b6e9;
        }

        .btn-deny {
          margin: 0 0 1em 0;
          color: #ffffff;
          background: #cb1212;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }
    }
  }

  .pmoRemarks {
    display: flex;
    flex-direction: column;

    .header {
      margin-bottom: 1em;

      & > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          margin: 0;
        }
      }
    }

    .body {
      width: 100%;
      max-width: 1120px;
    }
  }

  .addNewRemark {
    .formUI {
      border-radius: 0;
      background: none;
      box-shadow: none;
    }

    .remarks-button {
      display: flex;
      gap: 1em;
    }

    .css-1s2u09g-control {
      border-color: #707070;
    }

    .css-b62m3t-container {
      .css-319lph-ValueContainer,
      .css-g1d714-ValueContainer {
        padding: 11px 1em;
      }
    }
  }

  .send-button {
    .message-cont {
      justify-content: center;
    }

    .send-button-cont {
      div {
        margin: 0;

        button {
          margin: 0;
        }
      }
    }
  }
}
