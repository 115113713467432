.amenitiesForm {
  .formUI {
    padding: 2em;

    .legendContainer {
      display: flex;
      flex-direction: row !important;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          margin-right: 0.5em;
        }
      }
    }

    // .css-319lph-ValueContainer {
    //   padding: 12px 8px;
    // }

    // .css-1s2u09g-control {
    //   border-color: #212529;
    // }

    textarea.payment-instruction.form-control {
      background: #fff;
    }

    .message-text-result {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
