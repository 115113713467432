.form.form--login {
    width: 100%;

    .form-floating {
        color: #707070;

        &.error-label {
            label {
                color: #ff0000;
            }
        }
    }

    .form-control {
        border       : none !important;
        border-bottom: 2px solid #707070 !important;
        border-radius: 0 !important;
        background   : none !important;
    }

    .form-control:focus {
        border-color: #00B6E9 !important;
        box-shadow  : none !important;
    }
}