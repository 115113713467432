.upload-cont {
    display        : flex;
    justify-content: center;
    align-items    : center;
    gap            : 1em;
    margin         : 0 0 2.5em;

    .upload-input {
        label {
            display        : block;
            position       : relative;
            width          : 150px;
            height         : 150px;
            background     : #F7F7F7;
            display        : flex;
            align-items    : center;
            justify-content: center;
            color          : #AFAFAF;
            font-weight    : bold;
            cursor         : pointer;
            transition     : transform .2s ease-out;
            margin         : 0 auto;
            font-size      : 14px;
            padding        : 2em 2em 1em;
            align-items    : flex-end;
            position       : relative;

            &::before {
                content            : '';
                background         : url('../../../../assets/svg/upload.svg');
                position           : absolute;
                width              : 70px;
                height             : 70px;
                background-size    : contain;
                background-repeat  : no-repeat;
                background-position: center;
                top                : 0;
                bottom             : 0;
                margin             : auto 0;
            }
        }

        .custom-file-upload {
            opacity : 0;
            width   : 0.1px;
            height  : 0.1px;
            position: absolute;
        }
    }
}