.gatePass_viewRequest {
  padding: 3em;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  & > .row > .col > div,
  & > .row > .col-lg-4 > div {
    display: flex;
    flex-direction: column;
    margin: 0 1em 2em 0;

    h6 {
      color: #4e4e4e;
      font-size: 18px;
      font-weight: bold;
    }

    span {
      color: #4e4e4e;
    }

    .form-label {
      display: flex;
      align-items: center;
      margin: 0;
      height: 58px;
      color: #4e4e4e;
      font-size: 18px;
      font-weight: bold;
    }

    .form-control {
      padding: 1em;
      border: 1px solid #707070;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    button {
      display: flex;
      align-items: center;
      width: fit-content;
      margin: 2em 0;

      svg {
        margin-right: 1em;
        width: 20px;
        font-size: 18px;
        fill: #ffffff;
      }
    }
  }

  & > h6 {
    margin: 2em 0;
    color: #00b6e9;
    font-weight: bold;
  }

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 0s;

    div {
      display: flex;
      flex-direction: column;

      & > h6 {
        color: #4e4e4e;
        font-weight: bold;
      }

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 1em 0;
        width: 100%;

        .btn-approve {
          margin: 0 1em 1em 0;
          color: #ffffff;
          background: #00b6e9;
        }

        .btn-deny {
          margin: 0 1em 1em 0;
          color: #ffffff;
          background: #cb1212;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        .btn-light {
          margin: 0 0 1em 0;
        }
      }
    }
  }

  .buttons-cont {
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    
    .pmo-action {
      width: 100%;
      text-align: right;
    }

    .buttons {
      background: none;
    }
  }
}
