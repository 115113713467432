.admin-form.view-incident {
    input,
    textarea {
        border: none;
        background-color: #fff;
        padding-left: 0 !important;

        &:focus,
        &:active,
        &:hover {
            outline: unset;
            border: none;
            box-shadow: none;
        }
    }

    #formPMORemarks {
        border: 1px solid #ced4da;
        padding: .375rem .75rem !important;

        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
        }
    }

    .violation-btn {
        display: flex;
        gap: 1em;
        align-items: center;

        label {
            font-weight: normal;
            margin: 0;
        }
    }
}