.associationDuesVSOPEXFilter {
  display        : flex;
  flex-wrap      : nowrap;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : stretch;

  .filterUI {
    .form-group {
      display       : flex;
      flex-direction: row;
      flex          : wrap;
      align-items   : center;
      margin        : 0 1em 1em 0;
      padding       : 0;
      height        : 66px;


      &:first-child {
        margin-right: 3em;
      }
    }
  }

  .buttonWrapper {
    display    : flex;
    align-items: stretch;
    flex       : 1;
    width      : 100%;
    margin     : 1em 0;

    &>button {
      display      : flex;
      flex         : nowrap;
      align-items  : center;
      height       : 66px;
      margin-bottom: 1em;
      font-size    : 20px;

      svg {
        width       : 20px;
        height      : 20px;
        margin-right: 0.5em;
      }
    }

    &>button:first-child {
      margin: 0 1em 1em 0;
    }
  }
}