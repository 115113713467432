button.btn.btn-primary.remove-btn {
    background: none;
    color: #212529;
    text-decoration: underline;
    padding: 0;

    &:focus,
    &:active,
    &:hover {
        box-shadow: unset;
    }
}