.calendarForm {
  .download {
    padding: 1rem 1.5rem;
    background-color: #00b6e9;
    color: white;
    border-radius: 10px;
    text-decoration: none;

    svg {
      margin-right: 1rem;
    }
  }
  .upload-btn {
    background: #00b6e9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border-radius: 10px;
    color: white;
    &:hover {
      color: white;
    }
  }

  .form-select {
    height: 3rem;
    color: #868686;
  }
  #formSearch {
    height: 3rem;
    color: #868686;
  }
  .calendar-container-table {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 1.5rem;
    .greyish-container {
      border-radius: 10px;
      background-color: #c5c1c129;
      padding: 5rem 2rem;
      .year {
        padding: 0.8rem 1.5rem;
        color: white;
        background-color: #00b6e9;
        border-radius: 10px;
        text-decoration: none;
      }
    }
  }
  .dropdown-item {
    padding: 1rem !important;
  }
  .dropdown-item:first-child {
    border-bottom: 1px solid #a59d9d;
  }
  .dropdown-menu.show {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(-100px, 42px) !important;
  }

  .filter {
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #00b6e9;

      border-color: none;
    }
    .dropdown-menu.show {
      position: absolute;
      transform: translate(149px, -14px) !important;
      text-align: center;
    }
    .dropdown-item {
      padding: 0.7rem !important;
    }
    .dropdown-item {
      border-bottom: 1px solid #a59d9d;
    }
    .dropdown-item:last-child {
      border-bottom: none;
    }
  }
  .dropdown-toggle::after {
    content: none;
  }
  /* width */
  .list_container::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .list_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e3e3e3;
    background-color: #e3e3e3;
    border-radius: 10px;
  }

  /* Handle */
  .list_container::-webkit-scrollbar-thumb {
    background: #4e4e4e;
    border-radius: 10px;
  }

  /* Handle on hover */
  .list_container::-webkit-scrollbar-thumb:hover {
    background: #4e4e4e;
  }
  .data {
    direction: ltr;
  }
  .list_container {
    direction: rtl;
    overflow: auto;
    height: 450px;
    width: 100%;
    padding-left: 10px;
    overflow-x: hidden;

    table {
      direction: ltr;
      position: relative;
      th {
        font-size: 20px;
        letter-spacing: 1.25px;
        color: #2c2c2c;
        position: sticky;
        background-color: white;
        border: none;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      }
      td {
        font-size: 18px;
        padding: 2rem 0.5rem;
        border: none;
        border-bottom: 1px solid;
      }
      tbody {
        border: none;
      }
      th:nth-child(2),
      th:nth-child(3) {
        color: #00b6e9;
      }
      .account-type,
      .unit {
        color: #00b6e9;
      }
    }
  }
}
.calendar-container-table {
  .border-secondary {
    overflow-x: scroll !important;
    max-height: 500px;
    td {
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      min-width: 30px;
      border: 1px solid;
    }
  }
}
#test-table-xls-button {
  background-color: #00b6e9;
  color: white;
  border-radius: 15px;
  text-decoration: none;
  padding: 0.8rem 1rem;
  border: none;
  display: flex;
  align-items: center;
}
#test-table-xls-button:before {
  content: url('./downloadImg.svg');
  margin-right: 0.3rem;
}
