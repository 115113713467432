.dashboardExpendituresTable {
  ::-webkit-scrollbar {
    width : 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background   : #E3E3E3;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background   : #4E4E4E;
  }

  .tableUI {
    direction: rtl;
    overflow : auto;
    height   : 840px;

    table {
      position  : relative;
      direction : ltr;
      overflow-y: scroll;
      width     : 100%;

      thead {
        tr th {
          position  : sticky;
          top       : 0;
          z-index   : 999;
          text-align: left;
          background: #FFFFFF;
        }
      }

      tbody {
        tr td {
          text-align  : left;
          border-color: #D4D4D4 !important;
          white-space : nowrap;
          border      : none !important;
        }

        tr:last-child {
          position  : sticky;
          bottom    : 0;
          right     : 0;
          background: #FFFFFF;
        }

        tr:last-child td:nth-child(3) {
          color      : #00B6E9;
          font-weight: bold;
        }
      }
    }
  }
}