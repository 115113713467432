.gatePass_denyForm {
  .formUI {
    padding: 1em 2em;

    & > .row > .col > div,
    & > .row > .col-lg-4 > div {
      display: flex;
      flex-direction: column;
      margin: 0 1em 2em 0;

      h6 {
        color: #4e4e4e;
        font-size: 18px;
        font-weight: bold;
      }

      span {
        color: #4e4e4e;
      }

      .form-label {
        display: flex;
        align-items: center;
        margin: 0;
        height: 58px;
        color: #4e4e4e;
        font-size: 18px;
        font-weight: bold;
      }

      .form-control {
        padding: 1em;
        border: 1px solid #707070;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      button {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 2em 0;

        svg {
          margin-right: 1em;
          width: 20px;
          font-size: 18px;
          fill: #ffffff;
        }
      }
    }

    & > h6 {
      margin: 2em 0;
      color: #00b6e9;
      font-weight: bold;
    }

    .send-button {
      .message-text-result {
        justify-content: flex-start;
        display: flex;
        align-items: center;
      }

      .send-button-cont {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
          margin: 0;

          button {
            margin: 0;
          }
        }
      }
    }
  }
}
