.formSearch {
  position: relative;
}

.Budget {
  .upload-btn {
    background: #00b6e9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border-radius: 10px;
    color: white;
    &:hover {
      color: white;
    }
  }

  .form-select {
    height: 3rem;
    color: #868686;
  }
  #formSearch {
    height: 3rem;
    color: #868686;
  }
}

.calendarYear {
  color: #00b6e9;
  margin: 2rem 0;
  font-weight: 500;
}
