.unit-table {
    background-color: #F9F9F9;
    box-shadow: 0px 3px 6px #00000029;

    table {
        width: 100%;

        thead {
            tr {
                th {
                    border-right: solid 1px #707070;
                    border-bottom: solid 1px #707070;
                    padding: 1em 1em 1em 1em;

                    &:last-child {
                        border-right: none;
                    }

                    input {
                        background-color: #F9F9F9;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    border: none;
                    border-right: solid 1px #707070;
                    border-bottom: solid 1px #707070;

                    input {
                        width: 100%;
                        padding: 1em 1em 1em 1em;
                        background-color: #F9F9F9;
                        border: none;
                    }

                    &:last-child {
                        border-right: none;
                    }

                    select#classifications {
                        cursor: pointer;
                        width: 100%;
                        padding: 1em 1em 1em 1em;
                        border: none;                       
                    }
                }
            }
        }
    }
}