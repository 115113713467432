.gatePassForm {
  &>.formUI {
    padding: 2em;

    &>form>.row>.col-lg-4> {
      div {
        h6 {
          font-weight: bold;
        }
      }

    }

    table {
      tbody {
        tr td:first-child {
          span {
            display        : flex;
            align-items    : center;
            width          : 100%;
            height         : 58px;
            padding: 0 1em;
          }
        }
      }
    }
  }

  .disabled {
    background-color: #fff !important;
  }

  .disabled.prepared-by {
    border: none;
    padding: 0;
  }
}