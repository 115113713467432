.tabsUI {
    .nav-tabs {
        border-bottom: 3px solid #AFAFAF;

        .nav-link {
            height       : 58px;
            margin-right : 1.5rem;
            margin-bottom: -3px;
            padding      : 1rem 0;
            color        : #AFAFAF;
            font-size    : 20px;
            font-weight  : bold;
            border       : none;
            border-bottom: 3px solid transparent;

            &:hover {
                color       : #AFAFAF;
                border-color: #AFAFAF;
            }

            &.active {
                color       : #00B6E9;
                border-color: #00B6E9;
                background  : none;
            }
        }
    }
}