button.btn.btn-primary#filter {
    position  : relative;
    width     : 140px;
    text-align: right;

    &::after {
        display: none;
    }

    &::before {
        content   : '';
        width     : 25px;
        height    : 25px;
        position  : absolute;
        background: url('../../../assets/svg/filter.svg');
        left      : 30px;
    }
}