
.modal.terms-and-conditions {
    .modal-dialog {
      .modal-content {
        .modal-header {
          justify-content: center;
          border-bottom: none;
          padding: 2em 1em 0 1em;
  
          .companyLogo {
            margin: 0;
  
            span {
              font-size: 2em;
            }
          }
        }
  
        .modal-body {
          padding: 0 1em 2em 1em;
  
          .tc-cont {
            display: flex;
            flex-direction: column;
  
            h2 {
              text-align: center;
              font-family: Helvetica;
              font-weight: 600;
              font-size: 25px;
              color: #4e4e4e;
              margin: 0 0 1em;
            }
  
            ol,
            ul {
              li {
                p {
                  font-family: Helvetica;
                  font-size: 16px;
                  color: #4e4e4e;
  
                  a {
                    color: #2179BF;
                    font-family: Helvetica;
                    font-size: 16px;
                  }
                }
              }
            }
  
            .text {
              display: block;
              max-height: 30em;
              overflow-y: scroll;
            }
          }
        }
      }
    }
  }