@import 'assets/styles/scss/global.scss';

.formBuiling {
    label {
        font-weight: bold;
    }

    select,
    input {
        cursor: pointer;
        padding: .8rem 2.25rem .8rem .75rem;
    }

    .formSearch {
        position: relative;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1em;

        .cancel {
            box-shadow: $defaultShadow;
        }
    }

    .edit-container {
        text-align: right;

        .edit {
            display: flex;
            align-items: center;
            margin-right: auto;
            gap: 15px;
            padding-left: 20px;
            padding-right: 20px;

            svg {
                width: 20px;
            }
        }
    }

    .form-content {
        
        &::-webkit-scrollbar {
            width: 12px;
            cursor: pointer !important;
            position: relative;
            z-index: 999;
        }
         
        &::-webkit-scrollbar-track {
            background-color: #AFAFAF;
            border-radius: 10px;
        }
         
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #4E4E4E;
        }
    }
}