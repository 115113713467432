@import '../AccountManagementPage.scss';

.management-form {
    .form-label.header {
        color: $primaryColor;
    }

    .header {
        color: $primaryColor;
    }

    .form-check {
        display     : flex;
        align-items : center;
        gap         : 10px;
        padding-left: 0;

        .form-check-input {
            padding: 12px;
            margin : 0;

            &:checked[type=checkbox] {
                background-image     : url('../../../assets/svg/check.svg');
                background-color     : #fff;
                background-size      : 25px 20px;
                background-repeat    : no-repeat;
                background-position-y: 0%;
            }
        }

        .form-check-label {
            font-size: 16px;
        }
    }

    .form-control.read-only {
        background-color: #fff;
        border          : none;
        outline         : none;

        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
        }
    }

    .checkboxes {
        display    : flex;
        gap        : 1.5em;
        align-items: center;
    }
}