.ScheduleTable {
  .upload-btn {
    background: #00b6e9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 4px #00000029;
    border-radius: 10px;
    color: white;
    &:hover {
      color: white;
    }
  }

  .form-select {
    height: 3rem;
    color: #868686;
  }
  #formSearch {
    height: 3rem;
    color: #868686;
  }
  .schedule-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 1.5rem;

    h6 {
      color: #00b6e9;
    }
    .form-select {
      padding: 0.8rem;
      letter-spacing: 0px;
      color: #afafaf;
    }
    input,
    optgroup,
    select {
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      padding: 0.8rem !important;
      color: #afafaf;
    }
    input::placeholder {
      color: #afafaf !important;
    }
    .date {
      width: 100%;
      color: #afafaf;
      border: 1px solid #afafaf;
      padding: 0.7rem !important;
      color: #afafaf !important;
      border-radius: 6px;
    }
    .dailybutton{
      background-color:#00b6e9; 
      padding: 1rem 1.5rem;
      border-radius: 10px;
      text-decoration: none;
      color: white;
      svg{
        margin-right: 1rem;
      }
    }
  }
  .dropdown-item {
    padding: 1rem !important;
  }
  .dropdown-item:first-child {
    border-bottom: 1px solid #a59d9d;
  }
  .dropdown-menu.show {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(-100px, 42px) !important;
  }

  .filter {
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #00b6e9;

      border-color: none;
    }
    .dropdown-menu.show {
      position: absolute;
      transform: translate(149px, -14px) !important;
      text-align: center;
    }
    .dropdown-item {
      padding: 0.7rem !important;
    }
    .dropdown-item {
      border-bottom: 1px solid #a59d9d;
    }
    .dropdown-item:last-child {
      border-bottom: none;
    }
  }
  .dropdown-toggle::after {
    content: none;
  }
}
