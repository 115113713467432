@import '../../../assets/styles/scss/global.scss';

.building-table {
    thead {
        tr {
            th {
                color         : $primaryColor;
                padding-bottom: 1em;

            }
        }
    }

    tbody {
        tr {
            td {
                padding      : 1.5em 0;
                border-bottom: 1px solid #eaeaea;

                .action-button {
                    background-color: transparent !important;
                    width           : 50px;
                    padding         : 0;
                    height          : 100%;
                    border          : none;
                    outline         : none;

                    svg {
                        width : 36px;
                        height: min-content;
                    }


                    &:hover,
                    &:focus,
                    &:active {
                        outline   : none;
                        border    : none;
                        box-shadow: none !important;
                        background: transparent !important;
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    a.dropdown-item {
                        padding: 1em 1rem;

                        &:focus,
                        &:active {
                            background: $primaryColor;
                        }
                    }
                }
            }
        }
    }
}