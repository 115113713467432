.companyLogo {
    width         : 100%;
    margin        : 1em 0;
    font-weight   : bold;
    text-align    : center;
    white-space   : nowrap;
    letter-spacing: 2px;

    span:first-child {
        color: #2179BF;
    }

    span:last-child {
        color: #07C0EC;
    }
}