.admin-form.view-announcement {
    input,
    textarea {
        border: none;
        background-color: #fff;
        padding-left: 0 !important;

        &:focus,
        &:active,
        &:hover {
            outline: unset;
            border: none;
            box-shadow: none;
        }
    }

    #formPMORemarks {
        border: 1px solid #ced4da;
        padding: .375rem .75rem !important;

        &:focus,
        &:active,
        &:hover {
            box-shadow: none;
        }
    }

    .violation-btn {
        display: flex;
        gap: 1em;
        align-items: center;

        label {
            font-weight: normal;
            margin: 0;
        }
    }

    .violation-upload-images {
        .upload-cont.image-upload {
            justify-content: flex-start;

            .upload-input {
                label {
                    width: 101px;
                    height: 100px;
                    background: #F7F7F7;
                    display: flex;
                    justify-content: center;
                    color: #AFAFAF;
                    font-weight: normal;
                    cursor: pointer;
                    transition: transform 0.2s ease-out;
                    margin: 0 auto;
                    font-size: 14px;
                    padding: 1em 1em 1em;
                    align-items: flex-end;
                    position: relative;

                    &::before {
                        width: 35px;
                        height: 35px;
                        top: -1.5em;
                    }
                }
            }
        }
    }
}