.filterUI {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin: 1em 0;

  .input-group {
    width: 100%;
    max-width: 280px;
    margin: 0 1em 1em 0;
    height: 66px;

    .input-group-text {
      margin: 0;
      padding: 1em 1.5em;
      border-left: none;
      border-radius: 10px;
      background: #ffffff;
    }

    .form-control {
      margin: 0;

      font-weight: bold;
      letter-spacing: 0.175em;
      border: 1px solid #c7c7c7;
      border-right: none;
      border-radius: 10px;
    }

    .form-control:focus {
      box-shadow: none;
    }

    .form-control::placeholder {
      color: #c7c7c7;
      font-weight: 300;
    }
  }

  .form-group {
    margin: 0 1em 1em 0;
    padding: 0;

    &:first-child {
      margin-right: 3em;
    }

    .form-label {
      margin-right: 1em;
      color: #00b6e9;
      font-size: 18px;
    }

    .form-control {
      width: 200px;
      color: #c7c7c7;
      border-color: #c7c7c7;
      border-radius: 10px;

      &:focus {
        border-color: #ced4da;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .form-select {
    width: 100%;
    max-width: 280px;
    margin: 0 1em 1em 0;
    color: #868686;
    font-weight: bold;
    letter-spacing: 0.175em;
    border: 1px solid #c7c7c7;
    border-radius: 10px;

    &:focus {
      border-color: #ced4da;
      outline: none;
      box-shadow: none;
    }
  }

  .dropend {
    margin: 0 1em 1em 0;
    padding: 0;

    & > .btn-primary.dropdown-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: #00b6e9;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 0.75em;
      }

      span {
        font-size: 20px;
      }
    }

    .dropdown-menu {
      margin-left: 1em;
      padding: 0;
      transform: translate(146px, -40px) !important;

      div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0;
        padding: 0;
        background: #f7f7f7;
      }

      .dropdown-item {
        padding: 1em;
        text-align: center;
        border-bottom: 1px solid #c7c7c7;
      }

      dropdown-item:last-child {
        border: none;
      }

      button {
        margin: 0;
        color: #afafaf;
        border-bottom: 1px solid #c7c7c7;
        border-radius: 0;
        background: #f7f7f7;

        &:disabled,
        &[disabled] {
          color: #121212;
        }

        &:last-child {
          border: none;
        }
      }
    }

    .dropdown-toggle::after {
      display: none;
    }
  }
}
