.occupancyGraph {
  padding      : 1em;
  border-radius: 10px;
  background   : #FFFFFF;
  box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  .occupancyGraph__header {
    display        : flex;
    justify-content: center;
    margin         : 1em 0;

    span {
      font-size     : 24px;
      text-transform: uppercase;
    }
  }
}