.view-concern-amenities {
  padding: 3em;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  & > .row > .col > div,
  & > .row > .col-lg-4 > div {
    display: flex;
    flex-direction: column;
    margin: 0 1em 2em 0;

    h6 {
      color: #4e4e4e;
      font-size: 18px;
      font-weight: bold;
    }

    span {
      color: #4e4e4e;
    }

    .form-label {
      display: flex;
      align-items: center;
      margin: 0;
      height: 58px;
      color: #4e4e4e;
      font-size: 18px;
      font-weight: bold;
    }

    .form-control {
      padding: 1em;
      border: 1px solid #707070;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    button {
      display: flex;
      align-items: center;
      width: fit-content;
      margin: 2em 0;

      svg {
        margin-right: 1em;
        width: 20px;
        font-size: 18px;
        fill: #ffffff;
      }
    }
  }

  & > h6 {
    margin: 2em 0;
    color: #00b6e9;
    font-weight: bold;
  }

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 4em;
    width: 100%;

    div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 1em 0;

      .buttons-amenity {
        margin: 0;
        padding: 0;
      }

      .btn-approve {
        margin: 0 1em 0 0;
        color: #ffffff;
        background: #00b6e9;
      }

      .btn-deny {
        margin: 0 !important;
        padding: 1em 2em;
        color: #ffffff;
        border-radius: 10px;
        background: #cb1212;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }

      .payment-received {
        margin: 0 1em 0 0 !important;
      }
    }
  }

  h3.proof-of-payment {
    color: #00b6e9;
    font: normal normal bold 20px/20px Helvetica;
  }

  h6 {
    color: #4e4e4e;
    font-size: 18px;
    font-weight: bold;
  }

  .upload-cont {
    justify-content: flex-start;

    .upload-input.proof-of-payment {
      label {
        width: 90px;
        height: 90px;
        padding: 1em;
        font-size: 12px;

        &::before {
          width: 40px;
          height: 40px;
          top: -20px;
        }
      }
    }
  }
}
