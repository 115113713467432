.status-column {
  &.green {
    color: #45c33b;
  }

  &.yellow {
    color: #eab82b;
  }
}

span.first {
  display: block;
  padding: 0.5em;
  background: #55a5e3;
  color: #fff;
  width: 5em;
  text-align: center;
}

span.second {
  display: block;
  padding: 0.5em;
  background: #f25c05;
  color: #fff;
  width: 5em;
  text-align: center;
}

span.third {
  display: block;
  padding: 0.5em;
  background: #d93232;
  color: #fff;
  width: 5em;
  text-align: center;
}
.admin-table thead tr th:last-child {
  text-align: start;
}
