.formSearch {
    position: relative;
}

#formContent {
    position: relative;
    z-index: 10;
    
    &::-webkit-scrollbar {
        width: 15px;
        position: relative;
        z-index: 9999;
    }
      

    &::-webkit-scrollbar-track {
        background-color: #AFAFAF;
        border-radius: 10px;
      }
       
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #4E4E4E; 
        border-radius: 10px;
    }
    
    /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
        background: #383838; 
    }
}

.edit-container {
    text-align: right;
}
