.collectionList {
  .date {
    color: #c7c7c7;
    border: 1px solid #c7c7c7;
    padding: 0.5rem;
  }
  .date-span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
