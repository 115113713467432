$primaryColor : #00B6E9;
$defaultColor : #AFAFAF;
$defaultShadow: 0 6px 7px rgba(0, 0, 0, .075);
$defaultRadius: 7px;

@font-face {
    font-family: Helvetica;
    src        : url('../../fonts/Helvetica.ttf');
}

* {
    font-family: Helvetica;
}

.svg__wrapper {
    display        : flex;
    flex-wrap      : wrap;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-right   : 0.75rem;
    padding        : 0.5rem;
    border-radius  : 6px;
    background     : #00B6E9;
}

button.btn {
    border       : none;
    outline      : none;
    padding      : 1em 2em;
    border-radius: 7px;

    svg {
        width       : 18px;
        height      : 18px;
        margin-right: 0.75em;
        fill        : #FFFFFF;
    }

    span {
        font-size: 20px;
    }
}

button.btn.btn-primary {
    background-color: #00B6E9;
}

button.btn.btn-default {
    background-color: #fff;
}

button.btn.btn-submit {
    margin    : 0 1em 1em 0;
    color     : #FFFFFF;
    background: #00B6E9;
}

button.btn.btn-cancel {
    margin    : 0 0 1em 0;
    color     : #00B6E9;
    background: #FFFFFF;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

button.btn.btn-deny {
    margin    : 0 0 1em 0;
    color     : #FFFFFF;
    background: #CB1212;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

button.btn:hover,
button.btn:focus,
button.btn:active {
    outline   : none;
    border    : none;
    box-shadow: none;
}

button.btn.searchbtn {
    background-color: transparent;
    height          : 100%;
    padding         : 0 1em;
    position        : absolute;
    top             : 0;
    right           : 0;

    svg {
        width: 20px;
    }
}

div.google-visualization-tooltip {
    padding   : 0.5em;
    color     : #00B6E9;
    font-size : 20px;
    border    : none;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}