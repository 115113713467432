@import '../BuildingListPage.scss';

.unit-tabs {
    .nav-item {
        flex: 1;

        button {
            width: 100%;
            color: #AFAFAF;
            background-color: #F9F9F9;
            font-weight: bold;
            padding: 2em;

            &.active {
                color: #fff;
                background-color: $primaryColor !important;
                border-color: #dee2e6 #dee2e6 #fff;
            }
        }
    }
}