.dashboardCollectionsTable {
  .tableUI {
    table {
      thead {
        tr th {
          text-align: left;
        }
      }

      tbody {
        tr td {
          text-align  : left;
          border-color: #D4D4D4 !important;
        }

        tr td:first-child {
          color          : #00B6E9;
          text-decoration: underline;
        }
      }
    }
  }
}