.pagination {
  margin-top     : 2rem;
  justify-content: center;
  align-items    : center;
  display        : flex;

  p {
    margin: 0 2rem 0 0;
  }

  .page-link {
    background   : #00b6e9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color        : white;
  }
}

.Capex {
  .upload-btn {
    background   : #00b6e9 0% 0% no-repeat padding-box;
    box-shadow   : 0px 3px 4px #00000029;
    border-radius: 10px;
    color        : white;

    &:hover {
      color: white;
    }
  }

  .form-select {
    height: 3rem;
    color : #868686;
  }

  #formSearch {
    height: 3rem;
    color : #868686;
  }


}