.concerns_approveRequest {
  .formUI {
    margin-top: 2em;
    padding   : 2em;

    &>div:last-child {
      display        : flex;
      flex-wrap      : wrap;
      flex-direction : row;
      justify-content: flex-end;
      align-items    : center;
      margin-top     : 4em;
    }

    .css-b62m3t-container {
      .css-319lph-ValueContainer,
      .css-g1d714-ValueContainer {
        padding: 11px 1em;
      }
    }

    .buttons {
      width: 100%;

      .row {
        width: 100%;

        .btns-cont {
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }
}