.dashboardARAgingTable {
  ::-webkit-scrollbar {
    width : 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background   : #E3E3E3;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background   : #4E4E4E;
  }

  .tableUI {
    height    : 840px;
    direction : rtl;
    overflow-y: scroll;

    table {
      position : relative;
      direction: ltr;

      thead {
        tr th {
          position  : sticky;
          top       : 0;
          z-index   : 999;
          text-align: left;
          background: #FFFFFF;
        }

        tr th:first-child {
          color: #2C2C2C;
        }

        tr th:nth-child(2) {
          max-width  : 120px;
          white-space: normal;
          line-height: 100%;
        }

        tr th:nth-of-type(4),
        th:nth-of-type(5),
        th:nth-of-type(6),
        th:nth-of-type(7),
        th:nth-of-type(8),
        th:nth-of-type(9) {
          color     : #2C2C2C;
          text-align: center;
        }

        tr th:nth-of-type(4),
        th:nth-of-type(6),
        th:nth-of-type(8) {
          background: #D6D6D6;
        }
      }

      tbody {
        tr td {
          text-align: left;
          border    : none !important;
        }

        tr td:first-child {
          color          : #00B6E9;
          font-weight    : bold;
          text-decoration: none !important;
          white-space    : nowrap;
        }

        tr td:nth-of-type(4),
        td:nth-of-type(6),
        td:nth-of-type(8) {
          background: #D6D6D6;
        }

        tr:last-child td:nth-of-type(3) {
          color      : #00B6E9;
          font-weight: bold;
        }

        tr:last-child {
          position  : sticky;
          bottom    : 0;
          right     : 0;
          border-top: 1px solid #757575;
          background: #FFFFFF;
        }
      }
    }
  }
}