.sidebar {
    position      : relative;
    width         : 100%;
    max-width     : 320px;
    min-width     : 320px;
    min-height    : 100vh;
    padding-bottom: 10em;
    background    : #2C2C2C;

    .logo__wrapper {
        display        : flex;
        justify-content: center;
        align-items    : center;
        min-height     : 88px;
    }

    .sidebar__nav {
        display       : flex;
        padding-top   : 1.5rem;
        border-top    : 2px solid #777777;
        flex-direction: column;
    }

    .nav-item {
        display        : flex;
        flex-wrap      : nowrap;
        flex-direction : row;
        justify-content: flex-start;
        align-items    : center;
        margin         : 0.25rem 1.25rem;
        padding        : 0;
    }

    .nav-item span {
        padding  : 1rem;
        color    : #FFFFFF;
        font-size: 1.25rem;
    }

    .nav-item .svg__wrapper {
        min-height  : 45.61px;
        margin-right: 0;
        padding     : 1rem;
        background  : none !important;
    }

    .nav-item svg {
        width : 24px;
        height: 24px;
        fill  : #C0C0C0;
    }

    .nav-item:hover {
        border-radius: 6px;
        background   : #4D4D4D;
        transition   : all 0.4s ease-out;
    }

    .nav-item:hover .svg__wrapper {
        background: #00B6E9 !important;
        transition: all 0.4s ease-out;
    }

    .nav-item:hover svg {
        fill: #FFFFFF;
    }

    .nav-item.active {
        border-radius: 6px;
        background   : #4D4D4D;
    }

    .nav-item.active .svg__wrapper {
        background: #00B6E9 !important;
    }

    .nav-item.active .svg__wrapper svg {
        fill: #FFFFFF;
    }

    .nav-link {
        width    : 100%;
        padding  : 0.75rem 1rem;
        color    : #C0C0C0 !important;
        font-size: 0.9rem;
    }

    .accordion-item {
        display       : flex;
        flex-direction: column;
        margin        : 0.25rem 1.25rem;
        border        : none !important;
        background    : none !important;
    }

    .accordion-header {
        width: 100%;
    }

    .accordion-header .accordion-button {
        margin    : 0 !important;
        padding   : 0 !important;
        color     : #AFAFAF !important;
        border    : none !important;
        background: none !important;
        box-shadow: none !important;
    }

    .accordion-header .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23C0C0C0%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }

    .accordion-header .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }

    .accordion-header .svg__wrapper {
        margin-right: 0;
        padding     : 1rem;
        background  : none !important;
    }

    .accordion-header svg {
        width : 24px;
        height: 24px;
        fill  : #C0C0C0;
    }

    .accordion-header button:hover {
        border-radius: 6px !important;
        background   : #4D4D4D !important;
    }

    .accordion-header button:hover .svg__wrapper {
        background: #00B6E9 !important;
    }

    .accordion-header button:hover svg {
        fill: #FFFFFF !important;
    }

    .accordion-collapse {
        width: 100%;
    }

    .accordion-body {
        margin : 0;
        padding: 0 !important;
    }

    .sidebar__copyright {
        position       : absolute;
        bottom         : 0;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        width          : 100%;
        height         : 94px;
        padding        : 1rem 2rem;
        color          : #C0C0C0;
        font-size      : 1rem;
        line-height    : 140%;
    }
}