.col.item-column {
    max-width: 8em;
}

.admin-form.requirement-form {
    .item-no {
        input {
            background-color: #fff;
            outline: none;
            border: none;

            &:focus,
            &:active,
            &:hover {
                box-shadow: none;
            }
        }
    }
}