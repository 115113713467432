.loginUI {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 100%;
    min-height     : 100vh;
    padding        : 1em;

    .loginUI__header {
        display        : flex;
        flex-wrap      : nowrap;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        width          : 100%;
        max-width      : 440px;
        margin-bottom  : 1.25em;

        h3 {
            margin     : 1em 0;
            color      : #575757;
            font-size  : 1.85em;
            font-weight: bold;
        }

        p {
            margin   : 0;
            color    : #575757;
            font-size: 1.25em;
        }
    }

    .loginUI__body {
        width    : 100%;
        max-width: 440px;
    }

    .login_error_label {
        label {
            color    : rgb(202, 11, 11);
            font-size: 14px;
        }
    }

    .login_success_label {
        label {
            color    : rgb(0, 207, 17);
            font-size: 14px;
        }
    }

    .loginUI__footer {
        display        : flex;
        flex-wrap      : nowrap;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        width          : 100%;
        max-width      : 440px;
        margin-top     : 0;

        a {
            margin-bottom  : 1.5em;
            color          : #00B6E9;
            font-size      : 18px;
            text-decoration: none;
        }

        button {
            width        : 100%;
            font-size    : 1.25em;
            border-radius: 0.25em;
            background   : #00B6E9;
        }
    }
}