.admin-form {
    .border-disabled {
        border: none;
    
        &:disabled {
            background-color: #fff;
            padding: 0.8rem 2.25rem 0.8rem 0;
        }
    }

    .cancel {
        background: #F9F9F9 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 4px #00000029 !important;
        border-radius: 10px;
    }

    .cancel.cancel-back {
        color: #00B6E9;
    }

    .remarks {
        border-radius: 10px;
    }

    label.history-label {
        font-weight: normal !important;
    }
}
