@import '../../assets/styles/scss/global.scss';

.card.admin-form {
    padding      : 3em 2em 3em 2em;
    border-radius: $defaultRadius;

    form {
        label {
            font-weight: bold;
        }

        select,
        input {
            cursor : pointer;
            padding: .8rem 2.25rem .8rem .75rem;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] {
        -moz-appearance: textfield;
        }

        .buttons {
            display        : flex;
            justify-content: flex-end;
            gap            : 1em;

            .cancel {
                box-shadow: $defaultShadow;

                &:active {
                    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
                }
            }
        }
    }
}

.admin-table {
    thead {
        tr {
            th {
                color         : $primaryColor;
                padding-bottom: 1em;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding      : 1.5em 0;
                border-bottom: 1px solid #eaeaea;

                &:last-child {
                    text-align: right;
                }

                .action-button {
                    background-color: transparent !important;
                    width           : 50px;
                    padding         : 0;
                    height          : 100%;
                    border          : none;
                    outline         : none;

                    svg {
                        width : 36px;
                        height: min-content;
                    }


                    &:hover,
                    &:focus,
                    &:active {
                        outline   : none;
                        border    : none;
                        box-shadow: none !important;
                        background: transparent !important;
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    a.dropdown-item {
                        padding: 1em 1rem;

                        &:focus,
                        &:active {
                            background: $primaryColor;
                        }
                    }
                }
            }
        }
    }
}