.calendarUI {
  margin: 2em 0;

  h5 {
    margin-bottom: 1.5em;
    color: #00b6e9;
    font-weight: bold;
  }

  .react-calendar {
    padding: 2.5em;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;

    .react-calendar__navigation {
      display: flex;
      margin-bottom: 2em;

      .react-calendar__navigation__arrow {
        padding: 0.25em 1em;
        border: none;
        background: #00b6e9;
      }

      .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
        display: none;
      }

      .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
        display: none;
      }

      .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
        order: 1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
        order: 2;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .react-calendar__navigation__label {
        order: 3;
        flex: 0 !important;
        margin: 0 auto;
        padding: 0.5em;
        border: none;
        background: none;
        outline: none;

        .react-calendar__navigation__label__labelText {
          color: #00b6e9;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 1px;
          white-space: nowrap;
        }
      }
    }

    .react-calendar__month-view__weekdays {
      .react-calendar__month-view__weekdays__weekday {
        padding: 1em;
        text-align: center;
        border: 1px solid #949494;

        abbr {
          color: #575757;
          font-size: 22px;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

    .react-calendar__month-view__days {
    }

    .react-calendar__tile {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding: 1em 1em 5em 1em;
      border: 1px solid #949494;
      background: #ffffff;

      abbr {
        font-size: 20px;
      }

      .not-available {
        position: absolute;
        top: 15px;
        left: 18px;

        svg {
          width: 20px;
        }
      }
    }

    .react-calendar__tile--active {
      padding: 1em 1em 1em 1em;
      background: #00b6e9;

      abbr {
        flex-grow: 1;
        color: #ffffff;
      }

      .available-time {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        h6 {
          color: #ffffff;
          margin-bottom: 0.25em;
        }

        span {
          font-size: 14px;
          color: #ffffff;
        }
      }
    }

    .react-calendar__month-view__days__day:disabled {
      cursor: not-allowed;
      background-color: #e0e0e0;
    }

    .react-calendar__month-view__days__day {
      color: #575757;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      abbr {
        color: #babbab;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: #949494;
    }
  }
}
