.tableUI {
  border-radius: 10px;
  background   : #FFFFFF;
  box-shadow   : rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  .table>:not(:first-child) {
    border: none !important;
  }

  table {
    margin: 0;

    thead {
      tr th {
        padding    : 2em;
        color      : #00B6E9;
        font-size  : 18px;
        text-align : center;
        white-space: nowrap;
        border     : none !important;
      }
    }

    tbody {
      tr td {
        padding     : 2em;
        font-size   : 16px;
        text-align  : center;
        border-color: #D4D4D4 !important;
      }

      tr:last-child td {
        border: none !important;
      }
    }
  }

  .tableUI__status--resolved,
  .tableUI__status--paid {
    color      : #45C33B;
    font-weight: bold;
  }

  .tableUI__status--pending,
  .tableUI__status--payment {
    color      : #EAB82B;
    font-weight: bold;
  }

  .tableUI__status--denied {
    color      : #CB1212;
    font-weight: bold;
  }

  .tableUI__status--canceled {
    color      : #484848;
    font-weight: bold;
  }

  .tableUI__status--1st {
    width        : 75%;
    margin       : 0;
    padding      : 0.5em 1em;
    color        : #F9F9F9;
    font-weight  : bold;
    text-align   : center;
    border-radius: 3px;
    background   : #55A5E3;
  }

  .tableUI__status--2nd {
    width        : 75%;
    margin       : 0;
    padding      : 0.5em 1em;
    color        : #F9F9F9;
    font-weight  : bold;
    text-align   : center;
    border-radius: 3px;
    background   : #F25C05;
  }

  .tableUI__status--3rd {
    width        : 75%;
    margin       : 0;
    padding      : 0.5em 1em;
    color        : #F9F9F9;
    font-weight  : bold;
    text-align   : center;
    border-radius: 3px;
    background   : #D93232;
  }

  .tableUI__DRStatus {
    padding      : 0.75em 1em;
    color        : #FFFFFF;
    text-align   : center;
    border-radius: 10px;
  }

  .tableUI__DRStatus--approved {
    background: #3FD668;
  }

  .tableUI__DRStatus--denied {
    background: #D94848;
  }

  .tableUI__DRStatus--pending {
    background: #D97B00;
  }
}