.unit-table {
  table {
    tbody {
      tr {
        td {
          .css-1s2u09g-control {
            min-height: 49px;
            background-color: #f9f9f9;
            border: none;

            .css-319lph-ValueContainer {
                .css-6j8wv5-Input {
                    &:focus {
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }

                &:focus {
                    outline: none !important;
                }
            }
          }
        }
      }
    }
  }
}
