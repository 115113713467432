.soa{
    .container-table{

        .payment-table{
            background: #F7F7F7;
            padding: 1rem;
            border-radius: 1rem;
        }
        table > :not(:first-child) {
            border: none !important;
          }
          table > :not(caption) > * > * {
            border: none !important;
          }
          table {
           
            direction: ltr;
            position: relative;
            border:none th {
              font-size: 20px;
              letter-spacing: 1.25px;
              color: #2c2c2c;
              position: sticky;
              background-color: white;
              border: none;
              top: 0; /* Don't forget this, required for the stickiness */
              box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
            }
            .account-type,
            .unit,
            .total {
              color: #00b6e9;
            }
            thead{
                border-bottom: 1px solid #d9d7d7;
            }
            tbody {
              border: none;
              td {
                font-size: 18px;
                padding: 2rem 0.5rem;
               
                .dropdown-item {
                  padding: 0.7rem !important;
                }
                .dropdown-item {
                  border-bottom: 1px solid #a59d9d;
                }
                .dropdown-item:last-child {
                  border-bottom: none;
                }
                .dropdown-menu.show {
                  transform: translate(-80%, 42px) !important;
                  text-align: center;
                }
              }
              .show > .btn-primary.dropdown-toggle {
                color: black;
                background-color: white;
                border-color: white;
                box-shadow: none;
              }
              .show > .btn-primary.dropdown-toggle:focus {
                box-shadow: none !important;
              }
              .container-table button {
                padding: 0;
              }
              .dropdown-toggle::after {
                content: none;
              }
              button.btn.btn-primary {
                background-color: white;
                color: black;
                padding: 0;
              }
            }
          }
        
       .blueHeader{
           color: #00B6E9;
           font-size: 22px;
           font-weight: 700;
       }    
    }
}