.details {
    h3 {
        color: #00B6E9;
        padding-bottom: 1em;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        height: 100px;
        align-items: flex-end;
        display: flex;
    }

    .detail-lists {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 41px;

        li {
            padding: 1.5em 0;
            border-bottom: 1px solid transparent;
        }
    }
}