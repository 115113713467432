.dashboardCollectionEfficiencyTable {
  .tableUI {
    padding   : 2em;
    background: none;
    box-shadow: none !important;

    table {
      margin       : 0;
      margin-bottom: 3em;
      border       : none !important;

      thead {
        tr th {
          padding    : 1.25em 2em;
          color      : #00B6E9;
          white-space: nowrap;
        }

        tr:first-child th {
          height     : 50px;
          padding    : 0;
          font-weight: bold;
        }
      }

      tbody {
        tr td {
          padding    : 1.25em 2em;
          color      : #4E4E4E;
          font-size  : 16px;
          white-space: nowrap;
          border     : none !important;
        }

        tr:last-child td:nth-child(2) {
          color      : #00B6E9;
          font-weight: bold;
        }
      }
    }
  }

  &.tableOne {
    table {
      thead {
        tr th {
          text-align: left;
          background: #FFFFFF;
        }
      }

      tbody {
        tr td {
          text-align: left;
          background: #FFFFFF;
        }
      }
    }
  }

  &.tableTwo {
    table {
      thead {
        tr th {
          text-align: center;
        }
      }

      tbody {
        tr td {
          text-align: center;
        }
      }
    }
  }
}