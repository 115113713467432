@import '../../assets/styles/scss/global.scss';

.card.admin-form {
    padding      : 3em 2em 3em 2em;
    border-radius: $defaultRadius;

    form {
        label {
            font-weight: bold;
        }

        select,
        input {
            cursor : pointer;
            padding: .8rem 2.25rem .8rem .75rem;
        }

        .buttons {
            display        : flex;
            justify-content: flex-end;
            gap            : 1em;

            .cancel {
                box-shadow: $defaultShadow;

                &:active {
                    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
                }
            }
        }
    }
}