.occupancyReportsFilter {
  display        : flex;
  flex-wrap      : nowrap;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : stretch;

  .buttonWrapper {
    display    : flex;
    align-items: stretch;
    flex       : 1;
    width      : 100%;
    margin     : 1em 0;

    &>button {
      margin-bottom: 1em;
      font-size    : 20px;
    }

    &>button:first-child {
      margin: 0 1em 1em 0;
    }
  }
}