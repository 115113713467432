@import '../../assets/styles/scss/global.scss';

.finance-nav {
    &.nav-tabs {
        border-bottom: 3px solid $defaultColor;
    }

    .nav-item {
        .nav-link {
            font-weight   : bold;
            color         : $defaultColor;
            background    : none;
            border        : none;
            padding-left  : 0;
            margin-bottom : -3px;
            letter-spacing: 0.8px;

            &.active {
                background   : none;
                border       : none;
                color        : $primaryColor;
                border-bottom: 3px solid $primaryColor;
            }

            &:hover {
                color: $primaryColor;
            }
        }
    }
}