.buttons {
    .submit-buttons {
        text-align: right;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .message-text {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }
}