.dashboard_card_list {
    display       : flex;
    flex-wrap     : wrap;
    flex-direction: row;
    width         : 100%;

    &>div {
        flex  : 1 0 30%;
        margin: 0 2rem 2rem 0;
    }
}